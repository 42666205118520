<template>
  <div class="home">
    <h1 id="birthday">Happy Birthday Mom!</h1>
  </div>
  <canvas id="canvas"></canvas>
</template>

<script>
export default {
  name: 'Home',
  created: function () {
    var duration = 4 * 1000
    var animationEnd = Date.now() + duration
    var defaults = { startVelocity: 30, spread: 50, ticks: 60, zIndex: 0 }

    function randomInRange (min, max) {
      return Math.random() * (max - min) + min
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 20 * (duration / timeLeft)
      defaults.spread = 20 * (duration / timeLeft)
      defaults.startVelocity = 5 * (duration / timeLeft)
      defaults.ticks = 15 * (duration / timeLeft)
      window.confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        })
      )
      window.confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        })
      )
    }, 250)
  }
}
</script>

<style>
.home {
  overflow-x: hidden;
  overflow-y: hidden;
}
#birthday {
  transition: all 10s ease-in-out;
  overflow-y: hidden;
  font-size: 300%;
}
</style>
