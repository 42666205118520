<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg fixed-top row">
      <router-link tag="li" class="col" class-active="active" to="/">
        Confetti Cannon
      </router-link><br>
      <router-link tag="li" class="col" class-active="active" to="/message">
        Message
      </router-link><br>
      <router-link tag="li" class="col" class-active="active" to="/photos">
        Rice Photos
      </router-link>
    </nav>
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  font-size: xx-large;
  color: #2c3e50;
  padding-right: 2%;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
html {
  background: url(assets/stacked-waves-haikei.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
